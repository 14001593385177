import * as React from "react";
import AppContextProvider, { useAppContext } from "../../context/app";
import Header from "../Header";
import Footer from "../Footer";
import Mask from "../Mask";
import MenuDrawer from "../MenuDrawer";
import ClientPackage from "../ClientPackage";
import classes from "./layout.module.scss";

const MainMask = () => {
  const [{ drawer }, { toggleDrawer }] = useAppContext();

  const hasOverlay = drawer !== null;

  const handleCloseDrawer = () => toggleDrawer(null);

  return <Mask isActive={hasOverlay} dismiss={handleCloseDrawer} />;
};

const Layout = ({ children }) => {
  return (
    <div className={classes.root}>
      <AppContextProvider>
        <Header />
        {children}
        <Footer />
        <MainMask />
        <MenuDrawer />
        <ClientPackage />
      </AppContextProvider>
    </div>
  );
};

export default Layout;
