import * as React from "react";
import classes from "./footer.module.scss";
import logoDesktop from "../../assets/images/logo-desktop.svg";
import arrowUp from "../../assets/images/arrow-up.svg";

const Footer = () => {
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <p
            className={classes.backToTopBtn}
            onClick={() =>
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
            }
          >
            Back to top
            <img src={arrowUp} alt="up" />
          </p>
          <img src={logoDesktop} className={classes.logo} alt="logo" />
          <div className={classes.others}>
            {/* <p className={classes.link}>Privacy Policy</p>
            <div className={classes.separator}></div>
            <p className={classes.link}>FAQS</p> */}
          </div>
        </div>
        <p className={classes.copyRight}>
          © 2020 Curnon Watch. All rights reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
