import React, { createContext, useContext, useMemo, useState } from "react";

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [drawer, toggleDrawer] = useState(null);

  const appState = useMemo(() => ({ drawer }), [drawer]);

  const appApi = useMemo(() => ({ toggleDrawer }), []);

  const contextValue = useMemo(() => [appState, appApi], [appApi, appState]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export default AppContextProvider;

export const useAppContext = () => useContext(AppContext);
