import * as React from "react";
import classes from "./header.module.scss";
import logo from "../../assets/images/logo.svg";
import logoDesktop from "../../assets/images/logo-desktop.svg";
import menuIcon from "../../assets/images/menu.svg";
import Button from "../Button";
import { useAppContext } from "../../context/app";

const Header = () => {
  const [, { toggleDrawer }] = useAppContext();
  return (
    <div className={classes.root}>
      <div className={classes.placeholder} />
      <picture>
        <source media="(min-width: 1280px)" srcSet={logoDesktop} />
        <img src={logo} alt="logo" />
      </picture>
      <nav className={classes.nav}>
        <a className={classes.link}>Về chúng tôi</a>
        <a className={classes.link}>Các gói dịch vụ</a>
        <a className={classes.link}>Khách hàng</a>
        <a className={classes.link}>Thư viện sản phẩm</a>
      </nav>
      <div className={classes.actions}>
        <div className={classes.downloadBtnWrapper}>
          <Button onClick={() => toggleDrawer("download")}>Tải tài liệu</Button>
        </div>

        <button
          className={classes.toggleMenuBtn}
          onClick={() => toggleDrawer("menu")}
        >
          <img src={menuIcon} alt="menu" />
        </button>
      </div>
    </div>
  );
};

export default Header;
