import React, { forwardRef } from "react";
import defaultClasses from "./mask.module.scss";
import { mergeClasses } from "../../utils/mergeClasses";

const Mask = forwardRef(({ classes: propClasses, isActive, dismiss }, ref) => {
  const classes = mergeClasses(defaultClasses, propClasses);

  const className = isActive ? classes.root_active : classes.root;

  return <button ref={ref} className={className} onClick={dismiss} />;
});

export default Mask;
