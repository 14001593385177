import * as React from "react";
import { useAppContext } from "../../context/app";
import Button from "../Button";
import classes from "./menuDrawer.module.scss";
import closeIcon from "../../assets/images/x.svg";

const MenuDrawer = () => {
  const [{ drawer }, { toggleDrawer }] = useAppContext();

  const isOpen = drawer === "menu";

  const rootClassName = isOpen ? classes.root_open : classes.root;

  return (
    <div className={rootClassName}>
      <div className={classes.header}>
        <button
          className={classes.closeBtn}
          onClick={() => {
            toggleDrawer(null);
          }}
        >
          <img src={closeIcon} alt="x" />
        </button>
      </div>
      <div className={classes.body}>
        <p className={classes.link}>Về chúng tôi</p>
        <p className={classes.link}>Các gói dịch vụ</p>
        <p className={classes.link}>Khách hàng</p>
        <p className={classes.link}>Thư viện sản phẩm</p>
        <div className={classes.downloadBtnWrapper}>
          <Button onClick={() => toggleDrawer("download")}>
            Tải Client Package
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MenuDrawer;
