import * as React from "react";
import { useAppContext } from "../../context/app";
import Button from "../Button";
import classes from "./clientPackage.module.scss";
import { useForm } from "react-hook-form";
import closeIcon from "../../assets/images/x.svg";
import curnonClientPackage from "../../assets/resources/curnon-b2b-client-package.pdf";
const ClientPackage = () => {
  const [{ drawer }, { toggleDrawer }] = useAppContext();

  const isOpen = drawer === "download";

  const rootClassName = isOpen ? classes.root_open : classes.root;

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    console.log("data: ", data);
  };
  const downloadAndCloseForm = () => {
    toggleDrawer("download");
    // trigger the pdf file download
    window.open(curnonClientPackage, "_blank");
  };
  return (
    <div className={rootClassName}>
      <div className={classes.wrapper}>
        <button
          className={classes.closeBtn}
          onClick={() => {
            toggleDrawer(null);
          }}
        >
          <img src={closeIcon} alt="x" />
        </button>
        <p className={classes.title}>{"Tải về\nClient Package"}</p>
        <form
          action="#"
          className={classes.form}
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          name="clientPackage"
          autoComplete="on"
        >
          <input type="hidden" name="bot-field"></input>
          <input type="hidden" name="form-name" value="clientPackage"></input>
          <input
            className={classes.input}
            name="company_name"
            ref={register}
            placeholder="Name"
            required="true"
          />
          <input
            className={classes.input}
            name="email"
            ref={register}
            placeholder="Email"
            required="true"
          />
          <input
            className={classes.input}
            name="phone"
            ref={register}
            placeholder="Phone"
            required="true"
          />
          <textarea
            className={classes.input}
            name="note"
            ref={register}
            rows={4}
            placeholder="Note"
          />
          <div className={classes.btnWrapper}>
            <Button onClick={downloadAndCloseForm} type="submit">
              Tải Client Package
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ClientPackage;
