import * as React from "react";
import classes from "./button.module.scss";

const Button = ({ children, align = "left", ...rest }) => {
  return (
    <button className={`${classes.root}`} {...rest}>
      <span>{children}</span>
    </button>
  );
};

export default Button;
